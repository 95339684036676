import Image from '../Image';
import Link from '../Link';
import SingleLineText from '../SingleLineText';

const mock = {
  navigationTitle: SingleLineText.Mock,
  title: SingleLineText.Mock,
  navigationLink: Link.Mock,
  url: Link.Mock,
  id: SingleLineText.Mock,
  image: Image.Mock,
  description: SingleLineText.Mock,
  subtitle: SingleLineText.Mock,
};

export default mock;
