import Image from '../Image';
import Link from '../Link';
import SingleLineText from '../SingleLineText';

const defaultProps = {
  navigationTitle: SingleLineText.DefaultProps,
  title: SingleLineText.DefaultProps,
  navigationLink: Link.DefaultProps,
  url: Link.DefaultProps,
  id: SingleLineText.DefaultProps,
  image: Image.DefaultProps,
  description: SingleLineText.DefaultProps,
  subtitle: SingleLineText.DefaultProps,
};

export default defaultProps;
