const defaultProps = {
  default: {
    id: '',
    fields: {},
  },
  inject: (fieldDefaults) => {
    return {
      id: '',
      fields: fieldDefaults,
    };
  },
};

export default defaultProps;
