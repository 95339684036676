import React from "react";
import { Link as JSSLink } from "@sitecore-jss/sitecore-jss-react";

// link wrapper because sitecore jss's Link doesn't support querystring until v20
// we can't update to v20 with our current version of sitecore

export const constructFinalHref = (linkValue) => {
  let finalHref = linkValue.href;

  if (linkValue.querystring) {
    if (!linkValue.querystring.startsWith("?")) {
      finalHref += "?";
    }
    finalHref += linkValue.querystring;
  }

  if (linkValue.linktype !== "anchor" && linkValue.anchor) {
    if (!linkValue.anchor.startsWith("#")) {
      finalHref += "#";
    }
    finalHref += linkValue.anchor;
  }

  return finalHref;
};

const Link = ({ field, ...props }) => {
  if (!(field && field.value)) {
    return null;
  }

  return (
    <JSSLink
      {...props}
      field={{
        ...field,
        value: { ...field.value, href: constructFinalHref(field.value) },
      }}
    />
  );
};

export default Link;
