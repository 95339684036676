import Image from '../Image';
import Link from '../Link';
import SingleLineText from '../SingleLineText';

const propTypes = {
  navigationTitle: SingleLineText.PropTypes,
  title: SingleLineText.PropTypes,
  navigationLink: Link.PropTypes,
  url: Link.PropTypes,
  id: SingleLineText.PropTypes,
  image: Image.PropTypes,
  description: SingleLineText.PropTypes,
  subtitle: SingleLineText.PropTypes,
};

export default propTypes;
