import * as defaultProps from 'prop-types';
import Checkbox from './Checkbox';
import Image from './Image';
import Link from './Link';
import Multilist from './Multilist';
import RichText from './RichText';
import SingleLineText from './SingleLineText';
import ContentBlock from './ContentBlock';
import BasePage from './BasePage';
import ImageLink from './ImageLink';
import ImageContentLink from './ImageContentLink';
import SitecoreItem from './SItecoreItem';
import GraphQlPropTypes from './GraphQL';

export const PropTypes = {
  SingleLineText: SingleLineText.PropTypes,
  RichText: RichText.PropTypes,
  Multilist: Multilist.PropTypes,
  Link: Link.PropTypes,
  Image: Image.PropTypes,
  Checkbox: Checkbox.PropTypes,
  ContentBlock: ContentBlock.PropTypes,
  ImageLink: ImageLink.PropTypes,
  ImageContentLink: ImageContentLink.PropTypes,
  BasePage: BasePage.PropTypes,
  SitecoreItem: SitecoreItem.PropTypes,
  GraphQL: GraphQlPropTypes,
  ...defaultProps,
};

export const DefaultProps = {
  SingleLineText: SingleLineText.DefaultProps,
  RichText: RichText.DefaultProps,
  Multilist: Multilist.DefaultProps,
  Link: Link.DefaultProps,
  Image: Image.DefaultProps,
  Checkbox: Checkbox.DefaultProps,
  ContentBlock: ContentBlock.DefaultProps,
  ImageLink: ImageLink.DefaultProps,
  ImageContentLink: ImageContentLink.DefaultProps,
  BasePage: BasePage.DefaultProps,
  SitecoreItem: SitecoreItem.DefaultProps,
};

export const Mocks = {
  SingleLineText: SingleLineText.Mock,
  RichText: RichText.Mock,
  Multilist: Multilist.Mock,
  Link: Link.Mock,
  Image: Image.Mock,
  Checkbox: Checkbox.Mock,
  ContentBlock: ContentBlock.Mock,
  ImageLink: ImageLink.Mock,
  ImageContentLink: ImageContentLink.Mock,
  BasePage: BasePage.Mock,
  SitecoreItem: SitecoreItem.Mock,
};
