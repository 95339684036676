const mock = {
  default: {
    id: '',
    fields: {},
  },
  inject: (fieldDefaults) => {
    return {
      id: '1234098704987',
      fields: fieldDefaults,
    };
  },
};

export default mock;
