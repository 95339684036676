import defaultProps from './defaultProps';
import mock from './mock';
import propTypes from './propTypes';

const Link = {
  DefaultProps: defaultProps,
  Mock: mock,
  PropTypes: propTypes,
};

export default Link;
