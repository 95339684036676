import React from 'react';
import ModalVideo from 'react-modal-video';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';
import BannerImage from './Components/BannerImage';
import ContentItem from './Components/ContentItem';

class HeaderBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  render() {
    const { fields, params } = this.props;
    const { isFullWidthImage, image, videoId } = fields;
    return (
      <header
        className={`landing-banner ${
          isFullWidthImage.value ? 'full-width' : ''
        } ${!image.value.src ? 'no-image' : ''}`}
      >
        <div className="background" />
        {image.value.src && isFullWidthImage.value && (
          <BannerImage fields={fields} />
        )}
        <div className="l-padding">
          <ContentItem
            fields={fields}
            params={params}
            openModal={() => this.setState({ modalOpen: true })}
          />
          {image.value.src && !isFullWidthImage.value && (
            <div className="image">
              <Image className="img" field={image} />
            </div>
          )}
          {videoId.value && (
            <ModalVideo
              channel="youtube"
              isOpen={this.state.modalOpen}
              onClose={() => this.setState({ modalOpen: false })}
              videoId={videoId.value}
              youtube={{
                autoplay: 1,
                enablejsapi: 1,
                rel: 0,
              }}
            />
          )}
        </div>
      </header>
    );
  }
}

HeaderBanner.propTypes = PropTypes.SitecoreItem.inject({
  ...PropTypes.ImageContentLink,
  isFullWidthImage: PropTypes.Checkbox,
  videoId: PropTypes.SingleLineText,
  videoLinkText: PropTypes.SingleLineText,
  titleImage: PropTypes.Image,
  hasFade: PropTypes.Checkbox,
});

HeaderBanner.defaultProps = DefaultProps.SitecoreItem.inject({
  ...DefaultProps.ImageContentLink,
  isFullWidthImage: DefaultProps.Checkbox,
  videoId: DefaultProps.SingleLineText,
  videoLinkText: DefaultProps.SingleLineText,
  titleImage: DefaultProps.Image,
  hasFade: DefaultProps.Checkbox,
});

export default HeaderBanner;
