import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

export default propTypes;
