const mock = {
  value: {
    href: 'http://google.com',
    querystring: '',
    anchor: '',
    url: '',
    title: 'Mock link',
    class: '',
    linktype: '',
    text: 'VIsit google through this mock link',
  },
};

export default mock;
