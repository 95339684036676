import PropTypes from 'prop-types';
import Image from '../Image';

const GraphQLPropTypes = {
  basePage: {
    id: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.shape({
      value: PropTypes.string,
    }),
    image: PropTypes.shape({
      jss: Image.PropTypes,
    }),
    navigationLink: PropTypes.shape({
      url: PropTypes.string,
    }),
    navigationTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
  },
};

export default GraphQLPropTypes;
