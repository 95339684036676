import PropTypes from 'prop-types';

const propTypes = {
  default: PropTypes.shape({
    id: PropTypes.string,
    fields: PropTypes.any,
  }),
  inject: (fieldDefaults) => {
    return PropTypes.shape({
      id: PropTypes.string,
      fields: fieldDefaults,
    }).isRequired;
  },
};

export default propTypes;
