const defaultProps = {
  value: {
    href: '',
    querystring: '',
    anchor: '',
    url: '',
    title: '',
    class: '',
    linktype: '',
    text: '',
  },
};

export default defaultProps;
